import React from 'react';
import { useLocation } from 'react-router-dom';
import './DisplayText.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DisplayText() {
  const query = useQuery();
  const text = query.get('text');

  return (
    <div className="display-text-container">
      <h1>{text}</h1>
    </div>
  );
}

export default DisplayText;
