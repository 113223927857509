import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SubmitForm.css';

function SubmitForm() {
  const [text, setText] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/display?text=${encodeURIComponent(text)}`);
  };

  return (
    <div className="submit-form-container">
      <h1 className="title">What do you want to say?</h1>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="input"
          required
        />
        <button type="submit" className="button">Submit</button>
      </form>
    </div>
  );
}

export default SubmitForm;
