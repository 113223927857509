import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <header className="header">
        <h1>Quote Run</h1>
        <p>See our <a href="https://www.facebook.com/profile.php?id=61562762498256">Facebook page</a>.</p>
        <p>Your daily dose of inspiration</p>
        <p><Link to="/form" className="link-button">Create your own quote</Link></p>
      </header>
      <main className="main-content">
        <blockquote className="quote">
          <p>"The only limit to our realization of tomorrow is our doubts of today."</p>
          <footer>- Franklin D. Roosevelt</footer>
        </blockquote>
        <blockquote className="quote">
          <p>"The purpose of our lives is to be happy."</p>
          <footer>- Dalai Lama</footer>
        </blockquote>
        <blockquote className="quote">
          <p>"Life is what happens when you're busy making other plans."</p>
          <footer>- John Lennon</footer>
        </blockquote>
      </main>
      <footer className="footer">
        <p>&copy; 2024 QuoteRun. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
